import React from "react";
import Linkedin from "../../images/subHeader/itboomi_linkedin.svg";
import Instagram from "../../images/subHeader/itboomi_insta.svg";
import Twitter from "../../images/subHeader/itboomi_x.svg";
import styled from "styled-components";
import theme from "../theme/Theme";

function SubHeader() {
  return (
    <Container>
      <SubContainer>
        <ExtraLayer>
          <ContactDetails>
            <Number>+91 9080447727</Number>
            <Email>team@itboomi.com</Email>
          </ContactDetails>
          <SocialMediaIcons>
            <a
              href="https://www.instagram.com/itboomiindia/"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src={Instagram} alt="itboomi Instagram" />
            </a>
            <a
              href="https://twitter.com/itboomiindia"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src={Twitter} alt="itboomi Twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/itboomi/"
              target="_blank"
              rel="noreferrer"
            >
              <SocialIcon src={Linkedin} alt="itboomi LinkedIn" />
            </a>
          </SocialMediaIcons>
        </ExtraLayer>
      </SubContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const SubContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${theme.colors.primaryPink};
`;

const ExtraLayer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3rem;
  margin: 0 2rem;

  @media (max-width: 480px) {
    margin: 0 1rem;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

const Number = styled.p`
  margin: 0;
  font-size: 0.775rem;
  font-weight: 600;
  color: ${theme.colors.white};

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

const Email = styled.p`
  margin: 0;
  font-size: 0.775rem;
  font-weight: 600;
  color: ${theme.colors.white};

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

const SocialMediaIcons = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

const SocialIcon = styled.img`
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;

  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export default SubHeader;
